<template>
  <b-card-code>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Descripción"
            label-for="desc"
          >
            <b-form-input
              id="desc"
              v-model="item.DESCRIPCION"
              placeholder="desc"
              disabled
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Condicion"
            label-for="cond"
          >
            <b-form-textarea
              id="cond"
              v-model="item.CONDICION"
              placeholder="Condicion"
              rows="3"
              max-rows="6"
              style="white-space: pre;"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Cambio de Signo"
            label-for="signChange"
          >
            <v-select
              id="signChange"
              v-model="item.CAMBIO_SIGNO"
              label="text"
              :options="options"
              :reduce="option => option.value"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            to="../reports-rentability"
          >
            Cancelar
          </b-button>
        </b-col>

        <p>{{ errorMessage }}</p>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
    BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      item: {},
      loading: true,
      errorMessage: '',
      options: [
        { value: 'S', text: 'S' },
        { value: 'N', text: 'N' },
      ]
    }
  },
  computed: {
    statuses() {
      return this.$store.getters['catalog/allStatuses']
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      try {
        this.loading = true
        const { data } = await this.$http.get(`${environment.uri}/profits/rules/id/${this.$route.params.id}`)
        this.item = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    save(item) {
      this.loading = true
      this.errorMessage = ''
      this.$http.put(`${environment.uri}/profits/rules/id/${this.$route.params.id}`, {
        ID: this.$route.params.id,
        CAMPO: this.item.CAMPO,
        DESCRIPCION: this.item.DESCRIPCION,
        CAMBIO_SIGNO: this.item.CAMBIO_SIGNO
      })
        .then(() => {
          this.loading = false
          this.$router.replace('/cda/reports-rentability')
        })
        .catch(error => {
          console.error(error)
          this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
